// searchbox
.searchbox {
    position: relative;
    z-index: 20;
    overflow: hidden;
    margin-bottom: 10px;

    .form-control {
        position: relative;
        padding-right: 1.5rem;
    }

    .search-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        min-width: 34px;
        height: 34px;
        margin: 2px 0 2px 15px;
        line-height: 34px;
        text-align: center;
        transition: $transition-base;
        cursor: pointer;
        color: $green-light;
    }
}

.search_term {
    margin-bottom: 1rem;
}

.search-results {
    .card {
        @extend .default-card;
    }
}