// mini-sab
.mini-sab {

	background-color: $purple-gradient-1;
    
    .mini-search-book {
		display: block;
	}

	.container {
		.container-holder {

			@include media-breakpoint-down(sm) {
				// background-color: unset;
				// box-shadow: unset;
				// padding: 0;
				justify-content: center;

				.column {
					padding: 0 15px;
					max-width: 300px;
				}
			}

            // @include media-breakpoint-up(lg) {
			// 	padding-left: 20px;
			// 	padding-right: 20px;
			// }

			.wysiwyg {
				h1, h2, h3, h4, h5, h6,
				.h1, .h2, .h3, .h4, .h5, .h6 {
					color: $purple-dark;
                    width: max-content;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 1.5rem;

					&::after {
						background-color: $purple-dark;
					}
				}
			}
		}
	}

	.mobile-img {
		top: -45px;
		z-index: 1;
	}
}
