// searchbox
.searchbox {
    .search-icon {
        color: $blue;
    }
}

.search_term {
    margin-bottom: 1rem;
}

.search-results {
    .card {
        @extend .default-card;
    }
}