&.accommodation-search-book,
&.accommodation-category-detail,
&.accommodation-category-overview,
&.accommodation-detail {
    .main {
        section {
            &:last-child {
                padding-bottom: 180px;
            }
        }
    }
}

&.accommodation-detail {
    .main {
        padding-bottom: 180px;
    }
}

&.accommodation-category-detail,
&.accommodation-search-book,
&.accommodation-category-overview {
    .lead-section {
        background-image: url('/images/Sterren-transparant.png'), $gradient;
    }
}

&.accommodation-search-book,
&.accommodation-category-overview {
    .mini-sab {
        display: none;
    }

    .lead-section {
        padding-bottom: 200px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 240px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 360px;
        }

        .page-title {
            position: relative;

            &::before {
                content: '';
                background: url('/images/moon.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 552px;
                height: 157px;
                left: -60%;
                top: 100%;
                position: absolute;
                z-index: 1;
                display: none;
    
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
    
    
            &::after {
                content: '';
                background: url('/images/vlindertje_03.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 77px;
                height: 81px;
                left: 80%;
                top: 100%;
                z-index: 1;
                position: absolute;
                animation: butterfly-movement 3s linear 0s infinite;
    
                @include media-breakpoint-up(md) {
                    left: 80%;
                    width: 130px;
                    height: 136px;
                }
    
                @include media-breakpoint-up(lg) {
                    left: 100%;
                }
            }
        }
    }

    .bundle-overview-section,
    .content-section {
        @extend .bg-light;

        position: relative;
        background-image: url('/images/Sterren-transparant.png');

        .container {
            margin-top: -120px;
    
            @include media-breakpoint-up(md) {
                margin-top: -90px;
            }
            
            @include media-breakpoint-up(xl) {
                margin-top: -200px;
            }
        }

        &::before {
            content: '';
            background-image: url('/images/Sterren-transparant.png'), url('/images/light-circle.svg');
            background-position: center top;
            background-size: 50%, 120%;
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            bottom: 100%;
            
            @include media-breakpoint-up(xl) {
                height: 300px;
            }
        }
    }
}

&.accommodation-detail {
    .main {
        @extend .bg-light;
        background-image: url('/images/Sterren-transparant.png');
        background-size: 50%;
    }

    .lead-section {
        padding-top: 4rem;
    }

    .owl-carousel.accommodation-gallery .num {
        font-weight: 500;
    }
}

&.accommodation-category-detail {
    .bundle-detail-section {
        background-color: $purple-gradient-2;
        padding-bottom: 200px;
        color: $white;

        @include media-breakpoint-up(lg) {
            padding-bottom: 240px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 360px;
        }
    }

    .bundle-overview-section {
        @extend .bg-light;
        background-image: url('/images/Sterren-transparant.png');
        position: relative;

        .container {
            margin-top: -120px;
    
            @include media-breakpoint-up(md) {
                margin-top: -90px;
            }
            
            @include media-breakpoint-up(xl) {
                margin-top: -200px;
            }


        
            position: relative;

            &::before {
                content: '';
                background: url('/images/moon.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 552px;
                height: 157px;
                left: -10rem;
                top: -12rem;
                position: absolute;
                z-index: 1;
                display: none;
    
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
    
    
            &::after {
                content: '';
                background: url('/images/vlindertje_03.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 77px;
                height: 81px;
                left: 80%;
                top: -8rem;
                z-index: 1;
                position: absolute;
                animation: butterfly-movement 3s linear 0s infinite;
    
                @include media-breakpoint-up(md) {
                    left: 80%;
                    width: 130px;
                    height: 136px;
                }
    
                @include media-breakpoint-up(lg) {
                    left: 100%;
                }
        }
        }

        &::before {
            content: '';
            background-image: url('/images/Sterren-transparant.png'), url('/images/light-circle.svg');
            background-position: center top;
            background-size: 50%, 120%;
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            bottom: 100%;
            
            @include media-breakpoint-up(xl) {
                height: 300px;
            }
        }

        .collection {
            .card {
                text-align: center;
            }
        }
    }
}

&.accommodation-category-overview {
    .collection {
        .card {
            text-align: center;
        }
    }
}