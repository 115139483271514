// theme setup
@import '~framework/assets/scss/V2.1/0_config/_functions';
@import '~framework/assets/scss/V2.1/0_config/_mixins';
@import "./0_config/variables";
@import '~framework/assets/scss/V2.1/0_config/_variables';
@import '~framework/assets/scss/V2.1/1_vendor/bootstrap';

body.park {
	@import "./2_layout/header";
	@import "./2_layout/mini-sab";
	@import "./2_layout/sections";

	@import "./3_components/buttons";
}
