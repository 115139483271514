.collection {
    &.slider {
        .card {
            .card-body {
                text-align: center;
            }

            .card-title {
                font-size: $h5-font-size;
            }

            .card-subtitle {
                font-size: $font-size-base;
            }

            .card-description {
                font-size: $small-font-size;
            }
        }
    }
}

.slider-with-arrow {
    .card {
        @extend .card-custom;
    }
}

.collection-custom-card {
    .card {
        @extend .card-custom;
    }
}

.collection-two-columns.collection.grid {
    .grid-items .item {
        @include media-breakpoint-up(lg) {
            @include make-col(6);
        }
    }
}