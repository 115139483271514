&.service-overview {
    .bundle-overview-section {
        margin-top: 0;

        .service-overview {
            &.collection {
                .card {
                    border: 1px solid #D9D9D9 !important;
                }
            }
        }
    }
}

&.blog-post-overview {  
    .bundle-overview-section {
        margin-top: 0;

        &.collection { 
            .card {
                border: 1px solid #D9D9D9 !important;
            } 
        }  
     
    }
}



&.service-category-detail {
    .bundle-overview-section {
        .service-category-overview {
            &.collection {
                .card {
                    border: 1px solid #D9D9D9 !important;
                }
            }
        }
    }
}