.card {
    &.default {
        @extend .default-card;
    }
    
    &:not(.icon) {
        @extend .default-card;
    }
}

.card-custom {
    .card-title-top {
        background-color: $orange;
    }

    .card-description {

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: $purple;
        }

        ul {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }

            li {
                &::before {
                    color: $orange !important;
                }
            }
        }
    }
}
