// mini-sab
// &.home {
//     .mini-sab {
//         @include media-breakpoint-down(lg) {
//             padding-bottom: 4rem;
//         }
//     }
// }
.mini-sab {
    .container {
        .container-holder {
            @include media-breakpoint-down(xs) {
                padding-top: 20px;
            }
        }
    }

    .wysiwyg.d-lg-none {
        a {
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .mobile-img {
        top: -60px;
    }
}