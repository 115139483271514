body.resort {
	// theme setup
	@import '~framework/assets/scss/V2.1/0_config/_functions';
	@import '~framework/assets/scss/V2.1/0_config/_mixins';
	@import "./0_config/variables";
	@import '~framework/assets/scss/V2.1/0_config/_variables';
	@import '~framework/assets/scss/V2.1/1_vendor/bootstrap';

	// Call default styles
	@import "../default/2_layout/base";
	@import "../default/2_layout/header";
	@import "../default/2_layout/footer";

	@import "../default/3_components/lists";
	@import "../default/3_components/cards";

	@import "./2_layout/base";
	@import "./2_layout/eyecatcher";
	@import "./2_layout/header";
	@import "./2_layout/mini-sab";
	@import "./2_layout/sections";
	@import "./2_layout/footer";

	@import "./3_components/buttons";
	@import "./3_components/cards";
	@import "./3_components/collections";
	@import "./3_components/search-form";
	@import "./3_components/global-usps";
	@import "./3_components/accordion";
	@import "./3_components/forms";
	@import "./3_components/faq";

	@import "./7_themes/module.accommodations";
	@import "./7_themes/page.global";
}

:root {
	--w3-booking-filter-element-width: 200px;
}