// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 3rem 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-3rem + 30px);
}

// lead-section
// =========================================================================
&:not(.accommodation-detail) {
	.lead-section {
		background: $blue-gradient-1;
		color: $white;

		h1, h2, h3, h4, h5, h6, .btn-back {
			color: $white;
		}

		.container-one-column {
			max-width: 750px;
	
			.container-holder {
				text-align: center;
			}
		}
	}
}
.lead-section {
	padding: 7rem 0 3rem;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	position: relative;
	padding: 3rem 0;
	margin-top: -1px;

	&.bg-gradient:has( + .green-circle ) {
		padding-bottom: 185px;

		@include media-breakpoint-up(md) {
			padding-bottom: 240px;
		}

		@include media-breakpoint-up(xl) {
			padding-bottom: 340px;
		}
		
	}

	&.graas-svg + .content-section {
		margin-top: -3rem;
	}
}

&.home {
	.content-section {
		.container-two-columns {
			.container-holder {
				@extend .align-items-center;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3rem 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding-top: calc(8vh - 30px);
	margin-bottom: calc(-8vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	padding: 3rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3rem 0;
}

.title-section {
	position: relative;
	text-align: center;
	
	&.green-circle {
		background-image: url('/images/green-circle.svg');
		background-position: center top;
		background-size: 120%;
		margin-top: -90px;

		@include media-breakpoint-up(md) {
			margin-top: -130px;
		}
		
		@include media-breakpoint-up(xl) {
			margin-top: -240px;
		}
	}

	.resource-image {
		margin-top: -90px;
		padding-bottom: 140px;
		max-width: 430px;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-down(lg) {
			padding-bottom: 60px;
		}

		@include media-breakpoint-down(md) {
			padding-bottom: 50px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: -60px;
			max-width: 300px;
			padding-bottom: 0;
		}
	}

	.p-w-image {
		text-align: left;

		#p-w-blue {
			position: absolute;
			top: -50px;
			width: 100%;
			left: 0;
			right: 0;

			

			@include media-breakpoint-only(xs) {
				height: 60px;

				#p {
					x: 136%;
				}
	
				#w {
					x: -86%;
				}
			}

			@include media-breakpoint-only(sm) {
				height: 90px;
				top: -80px;

				#p {
					x: 120%;
				}

				#w {
					x: -72%;
				}
			}

			@include media-breakpoint-only(md) {
				height: 90px;
				top: -60px;

				#p {
					x: 164%;
				}

				#w {
					x: -116%;
				}
			}

			@include media-breakpoint-only(lg) {
				top: -70px;

				#p {
					x: 165%;
				}

				#w {
					x: -116%;
				}
			}

			@include media-breakpoint-up(xl) {
				top: -80px;
				width: auto;
				left: -7vw;
			}
		}
	}
}

.slider-section {
	padding: 0 !important;
	margin-top: -2px !important;
	margin-bottom: -2px !important;

	.container-one-column {
        .container-holder {
            margin: 0;

            .column {
                padding: 0;
            }
        }
    }
}

.butterfly-path {
	&::before {
		content: '';
		width: 55px;
		height: 70px;
		top: -40px;
		left: 32px;
		display: block;
		position: absolute;
		background-image: url('/images/vlindertje-lg.png');
		background-size: contain;
		background-repeat: no-repeat;
		animation: butterfly-movement 3s linear 0s infinite;

		@include media-breakpoint-up(md) {
			width: 101px;
			height: 129px;
			top: -20px;
			left: 60px;
		}
	}
}