// container
.container {

	@include media-breakpoint-down(md) {
		padding-left: 30px;
		padding-right: 30px;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 870px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}

	&.container-two-columns {
		.container-holder {
			> .column{
				&.one {
					@extend .pr-lg-4;
				}
				&.two {
					@extend .pl-lg-4;
				}
			}
		}
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

.round-img {
	overflow: hidden;
	border-radius: 20px;

	img {
		border-radius: 20px;
		transition: .3s;
	}

	&:hover {
		img {
			transform: scale(1.03);
		}
	}
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.3s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// owl-carousel navs
.owl-carousel {
	.owl-nav {
		.owl-prev,
		.owl-next {
			font-size: 1.5rem;
		}
	}

	// owl-carousel.slider
	&.slider {
		// owl-nav
		.owl-nav {
			.owl-prev,
			.owl-next {
				color: $primary;

				&:hover {
					color: darken($primary, 15%);
				}
			}
		}

		// owl-dots
		.owl-dots {
			.owl-dot {
				span {
					border-color: $primary;
				}

				&:hover,
				&.active {
					span {
						background: $primary;
					}
				}
			}
		}
	}
}

// main
.main {
	position: relative;
	z-index: 100;
}

// BG
.bg-gradient {
	background-image: url('/images/wolken-transparant.png'), $gradient;
	animation: clouds-movement 180s linear 0s infinite;

	// margin-top: -12rem !important;
    // padding-top: 15rem !important;

    // @include media-breakpoint-up(sm) {
	// 	// margin-top: -9rem !important;
	// 	padding-top: 12rem !important;
    // }

    // @include media-breakpoint-up(lg) {
    //     // margin-top: -3rem !important;
    //     padding-top: 7rem !important;
    // }
}

// default-card
.default-card {
	border-radius: 20px;
	//border: none !important; 

	.card-image { 
		.card-img {
			aspect-ratio: 4 / 3;
		}
	}

	.card-image-caption {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		padding: 5%;

		.card-image-label {
			color: $white;
			background-color: $green-dark;
			width: max-content;
			padding: 8px 24px;
			border-radius: 8px;
		}
	}

	.card-title-top {
        display: none;
    }

	.card-body {
		padding: 24px;
	}

	.card-title,
	.card-title-link,
	.card-subtitle {
		color: $blue;
		margin-bottom: .5rem;
	}

	.card-title {
		font-size: $h4-font-size;
	}

	.card-subtitle {
		font-size: $h5-font-size;
	}

	.list-icons {
		color: $orange;
		display: flex;
		justify-content: center;
		gap: .25rem;
		margin: 0 0 .5rem;

		.list-item {
			color: $orange;
			padding: 4px 10px;
			cursor: pointer;
			border: 1px solid #cecece;
			border-radius: 99px;
			display: flex;
			align-items: center;
			gap: 4px;
			line-height: normal;
			height: 25px;
			transition: 0.3s;

			.icon {
				position: static !important;
				font-size: 13px;

				i {
					font-weight: 300;
				}
			}

			.title {
				font-weight: 400;
				font-size: $small-font-size;
			}

			&:hover {
				background-color: $orange;
				color: $white;
				border: 1px solid $orange;

				.hover-label {
					bottom: calc(100% + 1em);
					z-index: 1;
					visibility: visible;
					opacity: 1;
				}
			}

			.hover-label {
				position: absolute;
				bottom: 100%;
				left: 50%;
				width: max-content;
				transform: translateX(-50%);
				background-color: $black;
				color: #fff;
				padding: 4px 15px;
				border-radius: 4px;
				filter: drop-shadow(2px 2px 2px rgba(#000, 0.4));
				z-index: -1;
				visibility: hidden;
				opacity: 0;
				transition: 0.3s;
				line-height: 1.5;
				font-size: $small-font-size;

				@include media-breakpoint-down(xl) {
					max-width: 100px;
					padding: 4px 10px;
					text-overflow: ellipsis;
					overflow-x: clip;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					bottom: -8px;
					left: 50%;
					transform: translateX(-50%);
					border-left: 10px solid transparent;
					border-top: 10px solid $black;
					border-right: 10px solid transparent;
				}
			}
		}
	}

	.card-description {
		ul:not(.list) {
			@extend .custom-list-extra;
			margin-top: 1rem;
		}

		.list-icons {
			display: none !important;
		}
	}

	.card-buttons {
		text-align: center;
	}
}

&:not(.home) {
	.default-card {
		border: 1px solid #D9D9D9;
	}
}

// svg
.graas-svg {
	margin-bottom: 10vh !important;
	overflow-x: clip;

	.card {
		border:none !important; 
	}

	&::before {
		content: '';
		background: url('/images/graas.svg');
		background-repeat: repeat-x;
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		top: -2rem;
	}

	&::after {
		content: url('/images/sec-close.svg');
		display: block;
		position: absolute;
		top: 100%;
		left: -1px;
		right: -1px;
		font-size: 0;
		line-height: 0;
		margin-top: -1px;

		@include media-breakpoint-up(xl) {
			background-position: center bottom;
		}
	}
}

// headings
.wysiwyg {
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: $blue;
	}
}




.landing-page {  
	&.collection { 
		.card {
			border: 1px solid #D9D9D9 !important;
		} 
	}
		 
}
