@keyframes clouds-movement {
    form {
        background-position-x: 0%;
    }

    to {
        background-position-x: -1000%;
    }
}
@-webkit-keyframes clouds-movement {
    form {
        background-position-x: 0%;
    }

    to {
        background-position-x: -1000%;
    }
}

@keyframes paper-fly-movement {
    0% {transform: translateY(0);}
    20% {transform: translateY(-20px) translateX(-30px) ;}
    40% {transform: translateY(-50px) translateX(-50px) ;}
    60% {transform: translateY(-30px) translateX(-20px) ;}
    80% {transform: translateY(-20px) translateX(-10px) ;}
    100% {transform: translateY(0);}
}
@-webkit-keyframes paper-fly-movement {
    0% {transform: translateY(0);}
    20% {transform: translateY(20px) translateX(-30px) ;}
    40% {transform: translateY(30px) translateX(-50px) ;}
    60% {transform: translateY(30px) translateX(-20px) ;}
    80% {transform: translateY(20px) translateX(-10px) ;}
    100% {transform: translateY(0);}
}

@keyframes horse-movement {
    0% {transform: rotateZ(0deg);}
    25% {transform: rotateZ(-5deg);}
    50% {transform: rotateZ(0deg);}
    75% {transform: rotateZ(5deg);}
    100% {transform: rotateZ(0deg);}
}
@-webkit-keyframes horse-movement {
    0% {transform: rotateZ(0deg);}
    25% {transform: rotateZ(-5deg);}
    50% {transform: rotateZ(0deg);}
    75% {transform: rotateZ(5deg);}
    100% {transform: rotateZ(0deg);}
}

@keyframes butterfly-movement {
    0% {transform: translateY(0);}
    50% {transform: translateY(-20px);}
    100% {transform: translateY(0);}
}

// @keyframes fadeInUp {
//     0% {
//         opacity: 0;
//         -webkit-transform: translate3d(0,50%,0);
//         transform: translate3d(0,50%,0)
//     }

//     to {
//         opacity: 1;
//         -webkit-transform: translateZ(0);
//         transform: translateZ(0)
//     }
// }
// @-webkit-keyframes fadeInUp {
//     0% {
//         opacity: 0;
//         -webkit-transform: translate3d(0,50%,0);
//         transform: translate3d(0,50%,0)
//     }

//     to {
//         opacity: 1;
//         -webkit-transform: translateZ(0);
//         transform: translateZ(0)
//     }
// }

// .animate__fadeInUp {
//     -webkit-animation-name: fadeInUp;
//     animation-name: fadeInUp;
//     -webkit-animation-duration: 1.5s;
//     animation-duration: 1.5s;
//     -webkit-animation-fill-mode: both;
//     animation-fill-mode: both
// }

// .mini-sab .desktop-img,
// .mini-sab .mobile-img,
// .title-section .img-fluid,
// .page-block.wysiwyg,
// .page-block.link,
// .page-block.title,
// .page-block.subtitle,
// .page-block.resource-image,
// .page-block.footer-list,
// .page-block.footer-logolink,
// .page-block.footer-text,
// .page-block.collection.grid .item,
// .page-block.collection.slider,
// .gallery-item,
// .global-usps li,
// .form {
//     &:not(.animate__fadeInUp) {
//         opacity: 0;
//     }
// }