
// owl-carousel navs
.owl-carousel {
	// owl-carousel.slider
	&.slider {
		// owl-nav
		.owl-nav {
			.owl-prev,
			.owl-next {
				color: $primary;

				&:hover {
					color: darken($primary, 15%);
				}
			}
		}

		// owl-dots
		.owl-dots {
			.owl-dot {
				span {
					border-color: $primary;
				}

				&:hover,
				&.active {
					span {
						background: $primary;
					}
				}
			}
		}
	}
}

// BG
.bg-gradient {
	background-image: url('/images/Sterren-transparant.png'), $gradient;
	animation: none;

	.page-block.title h1,
	.page-block.title h2,
	.page-block.title h3,
	.page-block.title h4,
	.page-block.title h5,
	.page-block.title h6,
	.page-block.wysiwyg {
		color: $white;
	} 
}

// default-card
.default-card {
	.card-title,
	.card-title-link,
	.card-subtitle {
		color: $purple-dark;
	}
}

// headings
.wysiwyg {
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: $purple;
	}
}