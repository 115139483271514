&.contact,
&.faq-overview,
&.privacy-statement,
&.disclaimer,
&.notice-cookie-consent-general-terms,
&.service-overview,
&.service-category-detail,
&.service-detail
{

    .mini-sab {
        .container-holder {
            margin-bottom: -2.25rem;
        }
    }

    .lead-section {
        background-image: url('/images/Sterren-transparant.png'), $gradient;
        padding-bottom: 200px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 240px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 360px;
        }

        .page-title {
            position: relative;

            &::before {
                content: '';
                background: url('/images/moon.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 552px;
                height: 157px;
                left: -60%;
                top: 100%;
                position: absolute;
                z-index: 1;
                display: none;
    
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
    
    
            &::after {
                content: '';
                background: url('/images/vlindertje_03.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 77px;
                height: 81px;
                left: 80%;
                top: 100%;
                z-index: 1;
                position: absolute;
                animation: butterfly-movement 3s linear 0s infinite;
    
                @include media-breakpoint-up(md) {
                    left: 80%;
                    width: 130px;
                    height: 136px;
                }
    
                @include media-breakpoint-up(lg) {
                    left: 100%;
                }
            }
        }
    }

    .bundle-overview-section,
    .content-section {
        @extend .bg-light;

        padding-bottom: 200px;

        position: relative;
        background-image: url('/images/Sterren-transparant.png');

        .container {
            margin-top: -120px;
    
            @include media-breakpoint-up(md) {
                margin-top: -90px;
            }
            
            @include media-breakpoint-up(xl) {
                margin-top: -200px;
            }
        }

        &::before {
            content: '';
            background-image: url('/images/Sterren-transparant.png'), url('/images/light-circle.svg');
            background-position: center top;
            background-size: 50%, 120%;
            display: block;
            width: 100%;
            height: 150px;
            position: absolute;
            bottom: 100%;
            
            @include media-breakpoint-up(xl) {
                height: 300px;
            }
        }
    }
}

&.error {
    .footer .footer-sitelinks {
        border-top: 180px solid #FFF;
    }
}