&.default {
    &.home {
        .content-section.bg-gradient {
            .collection {
                .card {
                    @include media-breakpoint-down(sm) {
                        text-align: center;
                    }
                    .card-description {
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
} 

