.page-block.image-slider {
    @include media-breakpoint-up(xl) {
        margin-top: -130px;
    }

    .slider-img-holder {
        overflow: hidden;
    }

    .slider-img {
        height: 100%;
        max-height: 635px;
        width: auto;
        max-width: none;
        object-position: 0 0;
        transition: .3s;
    }

	.slider-img-holder .marker {
		position: absolute;

		img {
			border-radius: 20px;
			border: 2px solid white;
		}
	}

	.slider-container {
		transition: .3s;
	}

    .slider-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;

        button {
            @extend .btn;
            @extend .btn-primary;

            border-radius: 50%;
            width: 40px;
            height: 40px;
            font-size: 20px;
            padding: 0 !important;

            &[disabled=disabled] {
                cursor: not-allowed;
            }

            &:focus,
            &:active:focus {
                box-shadow: none !important;
            }

            &:active {
                transform: scale(0.9);
            }
        }
    }
}
