// BG
.bg-gradient {
    background-image: url('/images/wolken-transparant.png'), $gradient;
}

// lead-section
// =========================================================================
.lead-section {
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 18rem 0 3rem;
    }
}

.content-section.bg-gradient {
    // padding-top: 20rem !important;

    // @include media-breakpoint-up(lg) {
    //     padding-top: 6rem !important;
    // }

    &::before {
        content: '';
		background: url('/images/dennen-blauw.png');
        background-size: contain;
		background-repeat: no-repeat;
        background-position: right top;
		display: block;
		position: absolute;
        width: 293px;
        height: 164px;
        bottom: -26px;
        left: -60px;

        @include media-breakpoint-up(lg) {
            width: 472px;
            height: 264px;
            bottom: -41px;
            left: -100px;
        }
    }

    &::after {
        content: '';
		background: url('/images/wipwap.png');
        background-size: contain;
		background-repeat: no-repeat;
        background-position: center;
		display: block;
		position: absolute;
        width: 75px;
        height: 60px;
        bottom: -10px;
        right: 12px;
        z-index: 1;
        // animation: horse-movement 2s linear 0s infinite;
        transform-origin: bottom;

        @include media-breakpoint-up(lg) {
            width: 166px;
            height: 133px;
            bottom: -2rem;
            right: 40px;
        }
    }

    .container-three-columns {
        .container-holder {
            > .column {
                position: static;
                &.one {
                    @include media-breakpoint-up(lg) {
                        @include make-col(2);
                    }

                    &::after {
                        content: '';
                        background: url('/images/vlieger.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        display: block;
                        position: absolute;
                        width: 273px;
                        height: 347px;
                        bottom: 100px;
                        left: -15px;
                        z-index: 1;
                        // animation: paper-fly-movement 10s linear 0s infinite;
                    }
                }
                &.two {
                    @include media-breakpoint-up(lg) {
                        @include make-col(8);
                    }
                }
                &.three {
                    @include media-breakpoint-up(lg) {
                        @include make-col(2);
                    }
                }
            }
        }

        .page-block.title {
            background-color: $primary;
            text-align: center;
            border-radius: 20px 20px 0px 0px;
            padding: 12px;

            transform: none !important;

            h1, h2, h3, h4, h5, h6,
            .h1, .h2, .h3, .h4, .h5, .h6 {
                color: $white;
                margin-bottom: 0;
            }
        }

        .page-block.wysiwyg {
            background-color: $white;
            padding: 25px 35px;
            border-radius: 0px 0px 20px 20px;

            transform: none !important;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    
}