// header
.header {
	position: sticky;
	z-index: 998;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;
	box-shadow: 0 4px 10px rgba($black, 0.2);
	background: $white;

	&.sticky {

		.logo {
			max-width: 190px;
		}
	}

	.container-lg {
		.container-holder {
			> .column {
				justify-content: space-between;

				@include media-breakpoint-up(lg) {
					justify-content: flex-end;
				}
			}
		}
	}

	/* header-top */
	.header-top {
		.menu {
			font-size: 14px;
			margin-bottom: 0;
			display: none;

			@include media-breakpoint-up(lg) {
				display: flex;
			}

			li {
				a {
					display: inline-block;
					padding: .5rem 15px;
				}
			}
		}
	}

	/* header-main */
	.header-main {
		padding: 4px 0;
		background-color: $green-light;
		position: relative;
		margin-bottom: -1px;
		
		@include media-breakpoint-down(md) {
			overflow-y: auto;
			max-height: calc(100vh - 42px);
		}

		@include media-breakpoint-up(lg) {
			padding: 8px 0;
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-lg;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: $white;
			font-size: $font-size-base;
			padding-top: 10px;
			padding-bottom: 10px;

			&-icon {
				line-height: 1.5em;
			}

			&-label {
				margin-left: 2px;
			}

			&[aria-expanded="true"] {
				.fa-bars {
					display: none;
				}
			}

			&[aria-expanded="false"] {
				.fa-times {
					display: none;
				}
			}
		}
	}

	// logo
	.logo {
		position: absolute;
		top: 5px;
		left: 0;
		right: 0;
		max-width: 190px;
		margin: 0 auto;
		transition: $transition-base;
		z-index: 1;

		@include media-breakpoint-up(lg) {
			top: 0;
			right: auto;
			max-width: 280px;
		}
	}

	// searchbox
	.searchbox {
		margin: 0;

		.form-control {
			width: 0;
			left: -100%;
			transition: $transition-base;

			@include media-breakpoint-up(lg) {
				left: auto;
				right: -100%;
			}

			&.show {
				width: 130px;
				left: 0;

				@include media-breakpoint-up(sm) {
					width: 200px;
				}

				@include media-breakpoint-up(lg) {
					width: 200px;
					left: auto;
					right: 0;
				}
			}
		}
	}

	// menu
	.menu {
		order: 1;

		.navbar-nav {
			.nav-item {
				.nav-link {
					padding-left: 15px !important;
					padding-right: 15px !important;
					color: $black;

					&.dropdown-toggle::after {
						display: inline-flex;
						justify-content: center;
						align-items: center;
						border: none;
						font-family: $font-awesome;
						font-weight: 900;
						font-size: 14px;
						width: 24px;
						height: 24px;
						margin-left: 0;
						vertical-align: middle;
						color: lighten($green-light, 50%);
						transition: $transition-base;
					}

					&[aria-expanded="true"] {
						&.dropdown-toggle::after {
							transform: rotateZ(180deg);
						}
					}
				}


				&:hover,
				&.active {
					> .nav-link {
						color: $link-hover-color;

						&.dropdown-toggle::after {
							color: $link-hover-color;
						}
					}
				}
			}
		}

		&#default_menu {
			.navbar-nav {
				.nav-item {
					// 1st level dropdown menu
					.dropdown-menu {
						padding: 0;
						border: none;
						border-radius: 0;
						box-shadow: none;
						background: transparent;
						white-space: nowrap;

						&::before,
						&::after {
							display: none;
						}

						.dropdown-item {
							&:hover {
								background-color: unset;
							}
						}

						> .nav-item {
							line-height: 1.2;

							.nav-link {
								padding-bottom: 0;
								font-weight: 400;
								color: $black;
							}

							&.dropdown {
								> .nav-link {
									font-weight: 600;
								}
							}

							&:hover,
							&.active {
								> .nav-link {
									color: $link-hover-color;
								}
							}

							// 2nd level dropdown menu
							.dropdown-menu {
								display: block !important;
								margin: 0;
								box-shadow: none;
								position: static;
								transform: none !important;
								padding: 0;
								min-width: 200px;

								.nav-item {
									margin: 0;

									.nav-link {
										font-weight: 400;
									}
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			background-color: $white;
			margin: 4px -15px -4px;

			.navbar-nav {
				> .nav-item {
					padding: 0 15px;
				}
			}

			&#default_menu {
				.navbar-nav {
					padding-top: 2rem;

					> .nav-item {
						border-bottom: 1px solid rgba($black, 0.3);

						> .nav-link {
							color: $green-dark;
						}
					}

					.nav-item {
						> .nav-link {
							font-weight: 600;
							display: flex;
							justify-content: space-between;

							&.dropdown-toggle::after {
								content: "\f067";
								color: $blue;
							}

							&[aria-expanded="true"] {
								&.dropdown-toggle::after {
									content: "\f068";
								}
							}
						}

						.dropdown-menu {
							position: static !important;
							margin-top: 0;
							padding-bottom: 0.875rem !important;

							.nav-item.dropdown {
								> .nav-link {
									color: $black !important;
								}
							}

							&.show {
								display: block;
								top: 4rem !important;
								transform: translate3d(0px, 0px, 0px) !important;
								left: 0 !important;
							}
						}
					}
				}
			}

			&#default_menu.show + .mobile-extra-menu {
				display: block !important;
			}
		}

		@include media-breakpoint-up(lg) {
			flex-grow: 0;
			order: unset;

			&#default_menu {
				.navbar-nav {
					.nav-item {
						> .nav-link {
							color: $white;

							&[aria-expanded="true"] {
								color: $link-hover-color;

								&.dropdown-toggle::after {
									color: $link-hover-color;
								}
							}
						}

						&:hover,
						&.active {
							> .nav-link {
								color: $link-hover-color;
							}
						}

						.dropdown-menu {
							.dropdown {
								> .nav-link {
									color: $link-hover-color;
								}
							}
						}
					}
				}
			}

			.navbar-nav {
				.nav-item {
					.nav-link {
						&.dropdown-toggle::after {
							content: "\f078";
						}
					}

					&:hover,
					&.active {
						> .nav-link {
							&.dropdown-toggle::after {
								color: $link-hover-color;
							}
						}
					}

					.dropdown-menu {
						&.show {
							display: block;
							top: 4rem !important;
							transform: translate3d(0px, 0px, 0px) !important;
							left: 0 !important;
						}

						&:has(.dropdown-menu) {
							&.show {
								position: fixed !important;
								top: 10rem !important;
								left: 50% !important;
								display: flex;
								transform: translate3d(-50%, 0px, 0px) !important;
							}
						}
					}
				}
			}
		}
	}

	// mobile-extra-menu
	div.mobile-extra-menu {
		order: 1;
		flex: 1;
		background-color: #fff;
        margin: 4px -15px -4px;
		display: none;

		.mobile-extra-menu {
			padding-top: 1rem;
			padding-bottom: 1rem;
			margin: 0;
			flex-direction: column;

			li {
				padding: 0 15px;

				a {
					display: inline-block;
					padding: .5rem 15px;
				}
			}
		}
	}

	// language
	.site-switcher {
		margin: 8px 0;
		font-weight: 500;

		@include media-breakpoint-up(lg) {
			margin: 13px 0;
		}

		.navbar-nav {
			margin-left: 15px;

			.nav-item {
				.nav-link {
					display: flex;
					align-items: center;
					border: 1px solid $black;
					border-radius: 99px;
					padding: 0 0.875rem !important;
					color: $black;
					font-size: 12px;
					line-height: 24px;
					text-transform: uppercase;

					@include media-breakpoint-up(lg) {
						line-height: 30px;
					}

					&::after {
						display: none;
					}

					.language-code {
						margin-right: 5px;
					}

					img {
						width: 14px;
						height: 12px;
					}

					&:hover:not(.active) {
						border-color: $black;
					}
				}

				.dropdown-menu {
					width: 100%;
					text-align: center;
					position: absolute;
					min-width: 0;
					padding: 0;
					border-radius: 6px;
					border-color: $black;
					overflow: hidden;

					.dropdown-item {
						padding: 0.25rem 0.5rem;

						img {
							max-width: 24px;
						}
					}
				}
			}
		}
	}

	// Ticket BTN
	a.header-btn {
		@extend .btn;
		@extend .btn-primary;

		display: none;
	}
}

// navbar-collapse-active
&.navbar-collapse-active {
	.header {
		background-color: $white;
		box-shadow: 0 4px 10px rgba($black, 0.2);
	}
}
