// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh !important; // overide
	min-height: 280px !important; // overide
	max-height: 540px;
	padding: unset; // overide
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 100vh !important;
	max-height: 330px;
	
	@include media-breakpoint-up(sm) {
		// max-height: calc(100vw / 1.77); // 16:9
	}
	
	@include media-breakpoint-up(xl) {
		// max-height: calc(100vw / 2.33); // 21:9
		max-height: 600px;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::after {
		content: url('/images/round.svg');
		z-index: 100;
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: 0;
		line-height: 0;
	}

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
				width: 110vw;
				max-height: calc(100vw / 1.77); // 16:9
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
				max-height: calc(100vw / 1.77); // 16:9
			}

			@include media-breakpoint-down(xs) {
				max-height: 364px;
			}

		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba($black, 0.25);
		}
	}

	// eyecatcher title
	.title {
		@extend .container;
		
		position: relative;
		z-index: 1;
		height: 79%;
		display: flex;
		align-items: center;
		justify-content: center;

	}
	.eyecatcher-title {
		text-align: center;
		color: $white;
		text-shadow: 0px 4px 4px rgba($black, 0.25);
	}
}
