div.global-usps {
    margin-top: 1rem;
    margin-bottom: 2rem;

    ul.global-usps {
        justify-content: center;
        @include make-row();
        
        li {
            @include make-col-ready();
            @include make-col(12);
            margin-bottom: 10px;
            max-width: 300px;
            
            @include media-breakpoint-up(md) {
                @include make-col(4);
            }

            .list-item-holder {
                background-color: $purple-light;
                color: darken($purple-dark, 29%);
                height: 100%;
                padding: 10px 20px;
                border-radius: 10px;

                display: flex;
                align-items: center;
                line-height: 17px;
                column-gap: 1.25rem;

                .list-item-image {
                    font-size: 1.5rem;
                }
            }
        }
    }
}