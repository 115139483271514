.form {
    border-radius: 20px;
    background-color: $white;
    border: none;
    padding: 30px;
}

.form-control:focus {
    border-color: $blue-darkest;
    box-shadow: none;
}