.card {
    &.default {
        @extend .default-card;
    }
}

.card-custom {
    .card-title-top {
        display: block;
        background-color: $red;
        color: $white;
        padding: 12px;
        text-align: center;
        font-size: 1.25rem;
        margin: 0;
    }

    .card-title {
        display: none;
    }

    .card-body {
        text-align: left !important;
    }

    .card-description {
        height: 100%;

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: $blue;
        }

        ul {
            @extend .custom-list-extra;
            margin-top: 0 !important;

            li {
                color: $black;

                &::before {
                    color: $green-dark !important;
                }
            }
        }

        ul:not(.list) + p {
			padding-left: 1.5rem;
		}

		.card-description-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
    }
}
