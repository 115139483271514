.faq-overview {
	> .faq-category {
		border-top: 1px solid $blue-darkest;
		
		&:last-child {
			border-bottom: 1px solid $blue-darkest;
		}
	}

	.faq-category {
		.faq-title {

            h2 {
                color: $blue-darkest;
            }

			.faq-icon {
				color: $blue-darkest;
			}

			&:hover {
				h2,
				.faq-icon {
					color: $blue;
				}
			}
		}
		.faq-items {
			.faq-item {
				&.active,
				&:hover {
					.faq-question {
						h3 {
							color: $blue;
						}
					}

					.faq-icon {
						color: $blue;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $blue-darkest;
				}

				.faq-question {
					h3 {
						color: $blue-darkest;
					}

					&:hover {
						.faq-icon {
							color: $blue;
						}
					}
				}
			}
		}
	}
}
