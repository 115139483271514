// mini-sab
.mini-sab {
	position: relative;
	z-index: 101;

	.mini-search-book {
		display: none;
	}

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		@include media-breakpoint-down(md) {
			padding-left: 45px;
			padding-right: 45px;
		}

		.container-holder {
			flex: 1;
			position: absolute;
			left: 30px;
			right: 30px;
			top: -70px;
			padding: 20px 0 24px;
			border-radius: 20px;
			background: $white;
			box-shadow: 0px 4px 4px rgba($black, 0.25);
			color: $black;
			text-align: center;

			> .column.default {
				padding-left: 40px;
				padding-right: 40px;
			}

			@include media-breakpoint-down(md) {
				left: 45px;
				right: 45px;
			}

			@include media-breakpoint-up(lg) {
				padding-top: 40px;
				padding-left: 20px;
				padding-right: 20px;
				top: -140px;
			}

			.wysiwyg {
				h1, h2, h3, h4, h5, h6,
				.h1, .h2, .h3, .h4, .h5, .h6 {
					color: $blue-dark;

					&::after {
						content: '';
						display: block;
						width: 100%;
						max-width: 240px;
						margin: 10px auto;
						height: 3px;
						background-color: $blue-dark;
						border-radius: 10px;
					}
				}

				p {
					max-width: 450px;
					margin: 0 auto;
					font-weight: 500;
				}
			}
		}
	}

	.mobile-img {
		@include media-breakpoint-up(lg) {
			display: none;
		}

		animation: butterfly-movement 3s linear 0s infinite;
		position: absolute;
		left: 20px;
		top: -77px;
	}
	.desktop-img {
		@include media-breakpoint-down(md) {
			display: none;
		}

		position: absolute;
		left: 0;
		right: 0;
		top: -197px;
	}

	// mini-timeslot
	.mini-timeslot {
        position: absolute;
        right: 50%;
		padding: 10px 24px;
		border-radius: 10px;
        background-color: $white;
		box-shadow: 0px 4px 4px rgba($black, 0.25);
		top: calc(100% + 24px);
		margin-top: 20px;
		width: max-content;
		transform: translateX(50%) !important;

		@include media-breakpoint-up(lg) {
			right: -40px;
			bottom: calc(100% + 40px);
			top: auto;
			margin-bottom: 10px;
			margin-top: 0;
			transform: translateX(0) !important;
		}

		h1, h2, h3, h4, h5, h6,
		.h1, .h2, .h3, .h4, .h5, .h6 {
			margin-bottom: 0;

			&::after {
				display: none !important;
			}
		}

		a {
			font-size: $small-font-size;
			text-decoration: underline;
		}
    }
}