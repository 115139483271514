.accordion-list-item {
    border-color: $blue-darkest;

    .accordion-list-item-header {
        h2 {
            margin: 0;
        }
        button {
            padding-left: 0;
            padding-right: 0;
            color: $blue-darkest;

            &:hover {
                text-decoration: none;
                color: $blue-dark;
            }
        }
    }
}