// footer
.footer {
    position: relative;
    z-index: 100;
    
    .footer-sitelinks {
        background-color: $blue;
        border-top: 180px solid $white;
        position: relative;
        padding-bottom: 40px;

        &::before {
            content: '';
            background-color: $blue;
            mask-image: url('/images/footer-vector.png');
            mask-position: top center;
            mask-repeat: repeat-x;
            mask-size: auto 100%;
            display: block;
            width: 100%;
            height: 180px;
            position: absolute;
            bottom: calc(100% - 2px);
        }

        .container-two-columns {
            .container-holder {
                > .column {
                    &.one {
                        @extend .pr-lg-3;

                        flex-flow: row wrap;
                        row-gap: 1rem;

                        @include media-breakpoint-down(sm) {
                            // order: 1;
                            padding-right: 30px;
                            padding-left: 30px;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            row-gap: 2rem;
                            @include make-col(8);
                        }
                    }

                    &.two {
                        @extend .pl-lg-3;

                        @include media-breakpoint-down(sm) {
                            padding-right: 30px;
                            padding-left: 30px;
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }
                    }
                }
            }
        }
    }

    .footer-logolink {
        @include media-breakpoint-down(sm) {
            order: 3;
        }

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }

        align-self: flex-start;
        flex-grow: 1;

        .list {
            gap: 10px;

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }
            

            .list-item {
                .link {
                    @extend .btn;
                    @extend .btn-primary;

                    border-radius: 99px !important;
                    font-size: 24px;
                    padding: 0 !important;
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }

    .footer-text {
        &.btns-holder {
            @include media-breakpoint-down(sm) {
                order: 1;
            }

            @include media-breakpoint-up(lg) {
                @include make-col(8);
            }

            align-self: flex-start;
            display: flex;
            flex-flow: row wrap;
            gap: 10px;

            p {
                margin-bottom: 0;
            }

            .btn {
                text-transform: none;
            }
        }
    }

    .footer-list {
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }

        .footer-list {
            @include make-col(12);

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        h5, .link {
            color: $blue-darkest;
        }

        h5 {
            font-size: $font-size-base;

            @include media-breakpoint-down(sm) {
                display: flex;
                align-items: center;
                cursor: pointer;

                &.active {
                    &::after {
                        transform: rotate(180deg);
                    }
                }

                &::after {
                    content: '\f078';
                    font-family: $font-awesome;
                    font-weight: 900;
                    margin-left: 1rem;
                }
            }
        }

        .link {
            font-size: 14px;

            &.active,
            &:hover {
                color: $red;
            }
        }
    }

    .highlight-box {
        background-color: $white;
        border-radius: 20px;
        text-align: center;
        padding: 20px 20px 4px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .newsletter-holder {
        order: 2;
        flex: 0 0 100%;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .footer-copyright {
        padding: 20px 0 46px;
        margin-top: 15px;

        .list {
            justify-content: center;
            gap: 1rem 1.5rem;
            font-size: 14px;

            .link {
                color: $secondary;
                
                &:hover {
                    color: $link-hover-color;
                }
            }
        }
    }
}
