// footer
.footer {
    .footer-sitelinks {
        background-color: $blue-dark;
        border: 0;
        padding-top: 2.5rem;

        &::before {
            content: '';
            background-color: $blue-dark;
        }
    }

    .footer-logolink {
        .list {
            .list-item {
                .link {
                    @extend .btn;
                    @extend .btn-primary;
                }
            }
        }
    }

    .footer-list {


        h5, .link {
            color: $white;
        }

        .link {

            &.active,
            &:hover {
                color: $link-color;
            }
        }
    }

    .footer-copyright {

        .list {
            .link {
                color: $purple-darkest;
                
                &:hover {
                    color: $link-color;
                }
            }
        }
    }
}