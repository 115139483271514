.faq-overview {
	margin-top: 1rem;
	margin-bottom: 1rem;

	> .faq-category {
		margin: 0;
		border-top: 1px solid $black;
		
		&:last-child {
			border-bottom: 1px solid $black;
		}
	}

	.faq-category {
		margin: 0;

		.faq-title {
			padding: 14px 50px 14px 0;

            h2 {
                text-transform: capitalize !important;
                color: $black;
                font-size: 20px;
                font-weight: 500;
                font-family: $font-family-base;
            }

			.faq-icon {
				color: $black;

				.fa-chevron-up::before {
                    content: "\f056"; // minus-circle
				}
			}

			&:hover {
				h2,
				.faq-icon {
					color: $primary;
				}
			}
		}
		.faq-items {
			.faq-item {
				&.active {
					.faq-icon {
						.fa::before {
							content: "\f056"; // minus-circle
						}
					}
				}

				&.active,
				&:hover {
					.faq-question {
						h3 {
							color: $primary;
						}
					}

					.faq-icon {
						color: $primary;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $black;
				}

				.faq-question {
					border: none;
					padding: 14px 50px 14px 0;
					margin: 0;

					h3 {
                        text-transform: capitalize !important;
						color: $black;
						font-size: 16px;
                        font-weight: 500;
                        font-family: $font-family-base;
                        transition: .2s ease;
					}

					&:hover {
						.faq-icon {
							color: $primary;
						}
					}
				}

				.faq-answer {
					padding: 0;
					border: none;
					margin: 0;

					a {
						color: $primary;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}

	.faq-icon {
		transition: .2s ease;
		right: 0;
		left: unset;
		font-size: 24px;
		height: 100%;
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
		color: $black;

        .fa {
            font-weight: 300;
        }

		.fa::before {
            content: "\f055"; // plus-circle
		}
	}
}
