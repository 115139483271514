.header {

	/* header-main */
	.header-main {
		background-color: $blue;
	}

	// Logo
	&.sticky {
		.logo {
			max-width: 210px;
			top: 10px;
		}
	} 

	// menu
	.menu {
		.navbar-nav {
			.nav-item {
				.nav-link {
					&.dropdown-toggle::after {
						color: lighten($blue-light, 25%);
					}
				}


				&:hover,
				&.active {
					> .nav-link {
						color: $link-hover-color;

						&.dropdown-toggle::after {
							color: $link-hover-color;
						}
					}
				}
			}
		}

		&#default_menu {
			.navbar-nav {
				.nav-item {
					// 1st level dropdown menu
					.dropdown-menu {
						> .nav-item {

							.nav-link {
								color: $black;
							}

							&:hover,
							&.active {
								> .nav-link {
									color: $blue-dark;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			&#default_menu {
				.navbar-nav {
					> .nav-item {
						> .nav-link {
							color: $blue-dark;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			&#default_menu {
				.navbar-nav {
					.nav-item {
						> .nav-link {
							color: $white;

							&[aria-expanded="true"] {
								color: $yellow;

								&.dropdown-toggle::after {
									color: $yellow;
								}
							}
						}

						&:hover,
						&.active {
							> .nav-link {
								color: $yellow;

								&.dropdown-toggle::after {
									color: $yellow;
								}
							}
						}

						.dropdown-menu {
							.dropdown {
								> .nav-link {
									color: $link-hover-color;
								}
							}
						}
					}
				}
			}

			.navbar-nav {
				.nav-item {
					&:hover,
					&.active {
						> .nav-link {
							&.dropdown-toggle::after {
								color: $link-hover-color;
							}
						}
					}
				}
			}
		}
	}
}
