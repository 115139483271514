.bg-dark {
    h1, h2, h3, h4, h5, h6 {
        color: $text-light;
    }
}

.bg-light {
    h1, h2, h3, h4, h5, h6 {
        color: $text-dark;
    }
}

.content-section.bg-gradient {
    // padding-top: 3rem !important;
    padding-bottom: 8rem;

    // @include media-breakpoint-up(md) {
    //     padding-top: 10rem !important;
    // }

    // @include media-breakpoint-up(lg) {
    //     padding-top: 7rem !important;
    // }
}

.light-holder {
    position: relative;

    // &::before {
    //     content: url('/images/lampion.png');
    //     display: block;
    //     position: absolute;
    //     bottom: calc(100% + 8vh);
    //     right: -3rem;

    //     @include media-breakpoint-down(md) {
    //         display: none;
    //     }

    //     @include media-breakpoint-down(lg) {
    //         bottom: calc(100% + 3vh);
    //         right: -1rem;
    //     }
    // }
}

.sec-divider {
    position: relative;
    background-image: url('/images/Sterren-transparant.png');
    padding-bottom: 185px;

    @include media-breakpoint-up(md) {
        padding-bottom: 170px;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 270px;
    }

    &::before {
        content: '';
        display: block;
        height: 83px;
        width: 100%;
        position: absolute;
        bottom: 100%;
        background: url('/images/sec-divider.svg');
        background-repeat: repeat-x;
        margin-bottom: -1px;
    }
}

.title-section {
	position: relative;
	text-align: center;
    padding: 4rem 0 10rem;
	
	&.light-circle {
		background-image: url('/images/Sterren-transparant.png'), url('/images/light-circle.svg');
		background-position: center top;
		background-size: contain, 120%;
        background-repeat: no-repeat;
		margin-top: -150px;

		@include media-breakpoint-up(md) {
			margin-top: -130px;
		}
		
		@include media-breakpoint-up(xl) {
			margin-top: -240px;
		}

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: -1;
            background-color: $light;
        }
	}

    .page-block.title {

        h1, h2, h3, h4, h5, h6 {
            color: $text-dark;
        }

        &::before {
            content: '';
            background: url('/images/moon.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 552px;
            height: 157px;
            left: -20%;
            bottom: 0;
            position: absolute;
            display: none;
            opacity: .75;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }


        &::after {
            content: '';
            background: url('/images/vlindertje_03.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 77px;
            height: 81px;
            left: 80%;
            top: -80px;
            position: absolute;
            animation: butterfly-movement 3s linear 0s infinite;

            @include media-breakpoint-up(md) {
                left: 80%;
                width: 130px;
                height: 136px;
            }

            @include media-breakpoint-up(lg) {
                left: 100%;
            }
        }
    }

    .wysiwyg {
        position: relative;
    }
}

.title-section + .content-section {
    background-image: url('/images/Sterren-transparant.png');

    margin-top: -10rem;
    padding-top: 1rem;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding-top: 6vh;
    }

    > .container:first-child {
        @include media-breakpoint-up(lg) {
            margin-top: -170px;
        }
    }

    padding-bottom: 13rem;
}