// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green:             #68B36F;
$green-dark:        #449645;
$green-light:       #A3CD84;

$blue:              #51BECA;
$blue-dark:         #2495A3;
$blue-darkest:      #07646F;
$blue-light:        #A1D5D8;
$blue-gradient-1:   #55BFCB;
$blue-gradient-2:   #9FD7DD;

$purple:            #BDA7D1;
$purple-dark:       #9176AD;
$purple-light:      #DDCBED;
$purple-gradient-1: #645277;
$purple-gradient-2: #D2BDE7;

$orange:            #F6A000;
$yellow:            #FFDE21;
$red:               #E43939;
$red-dark:          #b33c32;

$white:             #FFF;
$black:             #000;

// theme-colors
$primary: $red;
$secondary: $blue-dark;

// gradient
$gradient: linear-gradient(180deg, $blue-gradient-1 10%, $blue-gradient-2 100%);

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $black;
$link-decoration: none;
$link-hover-color: $green-dark;

// typography
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
$font-family-primary: 'Fredoka', serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-primary;
$headings-font-weight: 500;
$headings-color: $black;

// contextual
$light: $green-light;
$dark: $green-dark;
$text-light: $white;
$text-dark: $white;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

$transition-base: all 0.3s ease-in-out;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
