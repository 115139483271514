.header {

    // Ticket BTN
	a.header-btn {
		display: block;
	}

	// Logo
	&.sticky {
		.logo {
			max-width: 210px;
			top: 10px;
		}
	} 
}
